import React, { Dispatch, SetStateAction } from 'react';
import { useSquads } from '@store/squads';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import { replaceDashesWithSpaces } from '@utils/parseStrings';
import { useParams } from 'react-router-dom';
import { setGroupStatus } from '../../../helpers/setGroupStatus';

type SquadStatusSwitcherProps = {
  squadStatus: boolean | undefined;
  setStatusSquad: Dispatch<SetStateAction<boolean>>;
};

const SquadStatusSwitcher = (props: SquadStatusSwitcherProps) => {
  const { squadStatus, setStatusSquad } = props;
  const { name } = useParams();
  const { setSquadStatus } = useSquads();
  const { data: squad, error } = useGetSquad({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });

  return (
    <Box>
      <Switch
        defaultChecked={squad?.squad?.active}
        color="success"
        onChange={(e, checked) => {
          setStatusSquad(checked);
          setGroupStatus(setSquadStatus, squad?.squad?.squadId, checked);
        }}
      />
    </Box>
  );
};

export default SquadStatusSwitcher;
