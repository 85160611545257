import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CircularProgress, List, ListItem } from '@mui/material';
import CreateDashboardButton from '@pages/Marketplace/CreateDashboardButton';
import { useListDashboards } from '@services/api/marketplace/calls';

const ManageDashboardsList = () => {
  const { data, error, isLoading } = useListDashboards();

  const renderContent = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (!data?.dashboards?.dashboards.length) {
      return (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            One place to track all your active contracts, applications and interviews.
          </Typography>
          <CreateDashboardButton />
        </Box>
      );
    }

    return (
      <List>
        {data?.dashboards?.dashboards.map((dashboard, index) => (
          <Box key={dashboard.dashboardId}>
            <ListItem>
              <Link className="NavLink" to={`/${dashboard.dashboardId}`}>
                <Box>
                  <Box display="flex" alignContent="center" gap={1}>
                    <Typography>{dashboard.title}</Typography>
                  </Box>
                </Box>
              </Link>
            </ListItem>
          </Box>
        ))}
      </List>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1}>
        <Typography component="h5" color="inherit">
          Dashboards
        </Typography>
      </Box>
      {renderContent()}
    </Box>
  );
};

export default ManageDashboardsList;
