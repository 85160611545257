// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/interview.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Tags } from '../../../profiler/tags/v1/tags_pb.js';
import { Evaluations } from './evaluation_pb.js';

/**
 * @generated from message marketplace.demand.v1.InterviewTraits
 */
export class InterviewTraits extends Message<InterviewTraits> {
  /**
   * @generated from field: int32 price = 1;
   */
  price = 0;

  /**
   * @generated from field: int32 experience = 2;
   */
  experience = 0;

  constructor(data?: PartialMessage<InterviewTraits>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.InterviewTraits';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'price', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'experience', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InterviewTraits {
    return new InterviewTraits().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InterviewTraits {
    return new InterviewTraits().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InterviewTraits {
    return new InterviewTraits().fromJsonString(jsonString, options);
  }

  static equals(
    a: InterviewTraits | PlainMessage<InterviewTraits> | undefined,
    b: InterviewTraits | PlainMessage<InterviewTraits> | undefined
  ): boolean {
    return proto3.util.equals(InterviewTraits, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Interview
 */
export class Interview extends Message<Interview> {
  /**
   * @generated from field: string interview_id = 1;
   */
  interviewId = '';

  /**
   * @generated from field: marketplace.demand.v1.InterviewTraits traits = 2;
   */
  traits?: InterviewTraits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 3;
   */
  tags?: Tags;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: optional string requirements = 6;
   */
  requirements?: string;

  /**
   * @generated from field: string dashboard_id = 7;
   */
  dashboardId = '';

  constructor(data?: PartialMessage<Interview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Interview';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'traits', kind: 'message', T: InterviewTraits },
    { no: 3, name: 'tags', kind: 'message', T: Tags },
    { no: 4, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 5, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Interview {
    return new Interview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Interview {
    return new Interview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Interview {
    return new Interview().fromJsonString(jsonString, options);
  }

  static equals(
    a: Interview | PlainMessage<Interview> | undefined,
    b: Interview | PlainMessage<Interview> | undefined
  ): boolean {
    return proto3.util.equals(Interview, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CreateInterviewRequest
 */
export class CreateInterviewRequest extends Message<CreateInterviewRequest> {
  /**
   * @generated from oneof marketplace.demand.v1.CreateInterviewRequest.dashboard
   */
  dashboard:
    | {
        /**
         * @generated from field: string id = 1;
         */
        value: string;
        case: 'id';
      }
    | {
        /**
         * @generated from field: string nickname = 2;
         */
        value: string;
        case: 'nickname';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string requirements = 3;
   */
  requirements = '';

  /**
   * @generated from oneof marketplace.demand.v1.CreateInterviewRequest.interview
   */
  interview:
    | {
        /**
         * @generated from field: marketplace.demand.v1.CreateInterviewRequest.NewInterview new_interview = 4;
         */
        value: CreateInterviewRequest_NewInterview;
        case: 'newInterview';
      }
    | {
        /**
         * @generated from field: string contract_id = 5;
         */
        value: string;
        case: 'contractId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateInterviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CreateInterviewRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'dashboard' },
    { no: 2, name: 'nickname', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'dashboard' },
    { no: 3, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 4,
      name: 'new_interview',
      kind: 'message',
      T: CreateInterviewRequest_NewInterview,
      oneof: 'interview',
    },
    {
      no: 5,
      name: 'contract_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'interview',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateInterviewRequest {
    return new CreateInterviewRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateInterviewRequest {
    return new CreateInterviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateInterviewRequest {
    return new CreateInterviewRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateInterviewRequest | PlainMessage<CreateInterviewRequest> | undefined,
    b: CreateInterviewRequest | PlainMessage<CreateInterviewRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateInterviewRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CreateInterviewRequest.NewInterview
 */
export class CreateInterviewRequest_NewInterview extends Message<CreateInterviewRequest_NewInterview> {
  /**
   * @generated from field: marketplace.demand.v1.InterviewTraits traits = 1;
   */
  traits?: InterviewTraits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 2;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<CreateInterviewRequest_NewInterview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CreateInterviewRequest.NewInterview';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: InterviewTraits },
    { no: 2, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateInterviewRequest_NewInterview {
    return new CreateInterviewRequest_NewInterview().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateInterviewRequest_NewInterview {
    return new CreateInterviewRequest_NewInterview().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateInterviewRequest_NewInterview {
    return new CreateInterviewRequest_NewInterview().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateInterviewRequest_NewInterview
      | PlainMessage<CreateInterviewRequest_NewInterview>
      | undefined,
    b:
      | CreateInterviewRequest_NewInterview
      | PlainMessage<CreateInterviewRequest_NewInterview>
      | undefined
  ): boolean {
    return proto3.util.equals(CreateInterviewRequest_NewInterview, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.CreateInterviewResponse
 */
export class CreateInterviewResponse extends Message<CreateInterviewResponse> {
  /**
   * @generated from field: string interview_id = 1;
   */
  interviewId = '';

  constructor(data?: PartialMessage<CreateInterviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.CreateInterviewResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateInterviewResponse {
    return new CreateInterviewResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateInterviewResponse {
    return new CreateInterviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateInterviewResponse {
    return new CreateInterviewResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateInterviewResponse | PlainMessage<CreateInterviewResponse> | undefined,
    b: CreateInterviewResponse | PlainMessage<CreateInterviewResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateInterviewResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetInterviewRequest
 */
export class GetInterviewRequest extends Message<GetInterviewRequest> {
  /**
   * @generated from field: string interview_id = 1;
   */
  interviewId = '';

  constructor(data?: PartialMessage<GetInterviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetInterviewRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInterviewRequest {
    return new GetInterviewRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInterviewRequest {
    return new GetInterviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetInterviewRequest {
    return new GetInterviewRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetInterviewRequest | PlainMessage<GetInterviewRequest> | undefined,
    b: GetInterviewRequest | PlainMessage<GetInterviewRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetInterviewRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetInterviewResponse
 */
export class GetInterviewResponse extends Message<GetInterviewResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Interview interview = 1;
   */
  interview?: Interview;

  /**
   * @generated from field: marketplace.demand.v1.Evaluations evaluations = 2;
   */
  evaluations?: Evaluations;

  constructor(data?: PartialMessage<GetInterviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetInterviewResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview', kind: 'message', T: Interview },
    { no: 2, name: 'evaluations', kind: 'message', T: Evaluations },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInterviewResponse {
    return new GetInterviewResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInterviewResponse {
    return new GetInterviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetInterviewResponse {
    return new GetInterviewResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetInterviewResponse | PlainMessage<GetInterviewResponse> | undefined,
    b: GetInterviewResponse | PlainMessage<GetInterviewResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetInterviewResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateInterviewRequest
 */
export class UpdateInterviewRequest extends Message<UpdateInterviewRequest> {
  /**
   * @generated from field: string interview_id = 1;
   */
  interviewId = '';

  /**
   * @generated from field: marketplace.demand.v1.InterviewTraits traits = 3;
   */
  traits?: InterviewTraits;

  constructor(data?: PartialMessage<UpdateInterviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateInterviewRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'traits', kind: 'message', T: InterviewTraits },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateInterviewRequest {
    return new UpdateInterviewRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewRequest {
    return new UpdateInterviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewRequest {
    return new UpdateInterviewRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateInterviewRequest | PlainMessage<UpdateInterviewRequest> | undefined,
    b: UpdateInterviewRequest | PlainMessage<UpdateInterviewRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateInterviewRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateInterviewResponse
 */
export class UpdateInterviewResponse extends Message<UpdateInterviewResponse> {
  constructor(data?: PartialMessage<UpdateInterviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateInterviewResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateInterviewResponse {
    return new UpdateInterviewResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewResponse {
    return new UpdateInterviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewResponse {
    return new UpdateInterviewResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateInterviewResponse | PlainMessage<UpdateInterviewResponse> | undefined,
    b: UpdateInterviewResponse | PlainMessage<UpdateInterviewResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateInterviewResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateInterviewRequirementsRequest
 */
export class UpdateInterviewRequirementsRequest extends Message<UpdateInterviewRequirementsRequest> {
  /**
   * @generated from field: string interview_id = 1;
   */
  interviewId = '';

  /**
   * @generated from field: string requirements = 2;
   */
  requirements = '';

  constructor(data?: PartialMessage<UpdateInterviewRequirementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateInterviewRequirementsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateInterviewRequirementsRequest {
    return new UpdateInterviewRequirementsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewRequirementsRequest {
    return new UpdateInterviewRequirementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewRequirementsRequest {
    return new UpdateInterviewRequirementsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateInterviewRequirementsRequest
      | PlainMessage<UpdateInterviewRequirementsRequest>
      | undefined,
    b:
      | UpdateInterviewRequirementsRequest
      | PlainMessage<UpdateInterviewRequirementsRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateInterviewRequirementsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.UpdateInterviewRequirementsResponse
 */
export class UpdateInterviewRequirementsResponse extends Message<UpdateInterviewRequirementsResponse> {
  constructor(data?: PartialMessage<UpdateInterviewRequirementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.UpdateInterviewRequirementsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateInterviewRequirementsResponse {
    return new UpdateInterviewRequirementsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewRequirementsResponse {
    return new UpdateInterviewRequirementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateInterviewRequirementsResponse {
    return new UpdateInterviewRequirementsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateInterviewRequirementsResponse
      | PlainMessage<UpdateInterviewRequirementsResponse>
      | undefined,
    b:
      | UpdateInterviewRequirementsResponse
      | PlainMessage<UpdateInterviewRequirementsResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateInterviewRequirementsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteInterviewRequest
 */
export class DeleteInterviewRequest extends Message<DeleteInterviewRequest> {
  /**
   * @generated from field: string interview_id = 1;
   */
  interviewId = '';

  constructor(data?: PartialMessage<DeleteInterviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteInterviewRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteInterviewRequest {
    return new DeleteInterviewRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteInterviewRequest {
    return new DeleteInterviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteInterviewRequest {
    return new DeleteInterviewRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteInterviewRequest | PlainMessage<DeleteInterviewRequest> | undefined,
    b: DeleteInterviewRequest | PlainMessage<DeleteInterviewRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteInterviewRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteInterviewResponse
 */
export class DeleteInterviewResponse extends Message<DeleteInterviewResponse> {
  constructor(data?: PartialMessage<DeleteInterviewResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteInterviewResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteInterviewResponse {
    return new DeleteInterviewResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteInterviewResponse {
    return new DeleteInterviewResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteInterviewResponse {
    return new DeleteInterviewResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteInterviewResponse | PlainMessage<DeleteInterviewResponse> | undefined,
    b: DeleteInterviewResponse | PlainMessage<DeleteInterviewResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteInterviewResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListInterviewsForContractRequest
 */
export class ListInterviewsForContractRequest extends Message<ListInterviewsForContractRequest> {
  /**
   * @generated from field: string contract_id = 1;
   */
  contractId = '';

  constructor(data?: PartialMessage<ListInterviewsForContractRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListInterviewsForContractRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contract_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInterviewsForContractRequest {
    return new ListInterviewsForContractRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInterviewsForContractRequest {
    return new ListInterviewsForContractRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInterviewsForContractRequest {
    return new ListInterviewsForContractRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListInterviewsForContractRequest
      | PlainMessage<ListInterviewsForContractRequest>
      | undefined,
    b: ListInterviewsForContractRequest | PlainMessage<ListInterviewsForContractRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListInterviewsForContractRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListInterviewsForContractResponse
 */
export class ListInterviewsForContractResponse extends Message<ListInterviewsForContractResponse> {
  /**
   * @generated from field: repeated marketplace.demand.v1.Interview interviews = 1;
   */
  interviews: Interview[] = [];

  constructor(data?: PartialMessage<ListInterviewsForContractResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListInterviewsForContractResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'interviews', kind: 'message', T: Interview, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInterviewsForContractResponse {
    return new ListInterviewsForContractResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInterviewsForContractResponse {
    return new ListInterviewsForContractResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInterviewsForContractResponse {
    return new ListInterviewsForContractResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListInterviewsForContractResponse
      | PlainMessage<ListInterviewsForContractResponse>
      | undefined,
    b:
      | ListInterviewsForContractResponse
      | PlainMessage<ListInterviewsForContractResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(ListInterviewsForContractResponse, a, b);
  }
}
