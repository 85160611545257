import { Page } from '@components/Page/Page';
import { Chip, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useOrgs } from '@store/orgs';
import { useProfiler } from '@store/profiler';
import Container from '@mui/material/Container';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';
import ManageOrgKanbanboards from '@pages/Orgs/ManageOrgs/ManageOrgKanbanboards';
import Divider from '@mui/material/Divider';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import OrgMembers from '@pages/Orgs/OrgMembers';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Products from '@components/Products/Products';
import { useGetOrg } from '@services/api/profiler/OrgsQueries';
import { replaceDashesWithSpaces, replaceSpacesWithDashes } from '@utils/parseStrings';
import Avatar from '@mui/material/Avatar';
import { ROUTES_PREFIXES } from '@common/routes';
import SettingsIcon from '@mui/icons-material/Settings';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';

export const Org = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const { userProfile, listRoles, getListMembers } = useProfiler();
  const { getCurrentOrg } = useOrgs();
  const { data: org, isLoading } = useGetOrg({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });

  const requiredRoles = [IAMRoleStrings.IAM_ROLE_ADMIN, IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER];

  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[org?.org?.orgId as keyof typeof listRoles]?.find(
      (el) => el.id === userProfile?.profileId
    )?.roles.roles
  );

  useEffect(() => {
    if (org?.org?.orgId) {
      getListMembers({ id: org?.org?.orgId, type: TypeOfEntity.ORG });
    }
  }, [getCurrentOrg, getListMembers, org?.org?.orgId]);

  if (isLoading) return <LoaderWithDelay />;

  return (
    <Page description="Orgs" title="Orgs">
      <EntityInviteCard entityId={org?.org?.orgId} entity={org?.org} />
      <Container>
        <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              <Avatar
                sx={{ width: 200, height: 200, cursor: 'pointer' }}
                variant="rounded"
                src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${org?.org?.orgId}/${org?.org?.avatar}`}
              />
            </Box>
            <Box width="100%">
              <Box display="flex" gap={1}>
                <Typography variant="subtitle1">{org?.org?.name}</Typography>
                {hasAccess && org?.org?.name && (
                  <Link
                    className="NavLink"
                    to={`${ROUTES_PREFIXES.ORGS}/${replaceSpacesWithDashes(org?.org?.name)}/manage`}
                  >
                    <SettingsIcon cursor="pointer" />
                  </Link>
                )}
              </Box>

              <Typography flexGrow="grow" sx={{ color: '#bbb' }} variant="subtitle2">
                {org?.org?.about || 'No title'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <ManageOrgKanbanboards />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box display="flex" gap={0.5} flexWrap="wrap">
              {org?.org?.tags?.tags?.map((tag) => (
                <Chip label={tag?.alias || tag.value} />
              ))}
            </Box>

            {org?.org && <OrgMembers entityId={org?.org?.orgId} />}
          </Box>
        </Box>
        {org?.org?.orgId && <Products id={org?.org?.orgId} type={TypeOfEntity.ORG} />}
      </Container>
    </Page>
  );
};
