import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import Box from '@mui/material/Box';
import { ROUTES_PREFIXES } from '@common/routes';
import { Link } from 'react-router-dom';
import { QueryParamConfig, SetQuery } from 'use-query-params';
import Card from '@components/Card/Card';

type TExploreOrgCardProps = {
  org: any;
  setQuery?: SetQuery<{
    option: QueryParamConfig<(string | null)[] | null | undefined, (string | null)[] | never[]>;
  }>;
  option?: (string | null)[] | never[];
};

const ExploreOrgCard = (props: TExploreOrgCardProps) => {
  const { org, setQuery, option } = props;
  const link = `${ROUTES_PREFIXES.ORGS}/${org.name}`;

  return (
    <Card>
      <Box display="flex" gap={1}>
        <Link className="NavLink" to={link}>
          <Avatar
            sx={{ width: 40, height: 40 }}
            variant="rounded"
            src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${org?.orgId}/${org?.avatar}`}
          />
        </Link>
        <Box>
          <Link className="NavLink" to={link}>
            <Box display="flex">
              <Box>
                <Typography fontWeight="bold">{org.name}</Typography>
                <Typography sx={{ wordBreak: 'break-all' }} variant="body2" color="text.secondary">
                  {org.about}
                </Typography>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    </Card>
  );
};

export default ExploreOrgCard;
