import { useMarketplace } from '@store/marketplace';
import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import AppliedDemandsSection from '@pages/Main/MainPage/AppliedDemandsSection';

const ListAppliedDemands = () => {
  const { listAppliedDemands } = useMarketplace();

  useEffect(() => {
    return listAppliedDemands();
  }, [listAppliedDemands]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <AppliedDemandsSection />
    </Box>
  );
};

export default ListAppliedDemands;
