import { getClient } from '@services/api/helpers';
import { AvatarService } from '@proto/profiler/avatars/v1/avatar_connect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ActivateAvatarRequest,
  ActivateAvatarResponse,
  GetAvatarUploadUrlRequest,
  GetAvatarUploadUrlResponse,
  ListAvatarsRequest,
  ListAvatarsResponse,
} from '@proto/profiler/avatars/v1/avatar_pb';
import axios from 'axios';
import { useAppDispatch } from '@store/helpers';

const avatarClient = getClient<typeof AvatarService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AvatarService
);

export const useGetAvatarUrl = (file: any) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const getAvatarUrl = async (payload: {
    fileName: string;
    contentLength: bigint;
    entityId: string;
  }): Promise<GetAvatarUploadUrlResponse> => {
    return avatarClient.getAvatarUploadUrl(
      new GetAvatarUploadUrlRequest({
        fileName: payload.fileName,
        contentLength: payload.contentLength,
        entityId: payload.entityId,
      })
    );
  };

  const activateAvatar = async (url: string) => {
    await avatarClient.activateAvatar(
      new ActivateAvatarRequest({
        option: {
          value: url,
          case: 'url',
        },
      })
    );
  };

  return useMutation({
    mutationFn: getAvatarUrl,
    onSuccess: async (res) => {
      await axios.put(res.url, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      await activateAvatar(res.url);
      await queryClient.invalidateQueries({
        queryKey: ['myProfile'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['currentSquad'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['currentOrg'],
      });
    },
  });
};

export const useListAvatars = (payload: ListAvatarsRequest) => {
  const listAvatars = async (): Promise<ListAvatarsResponse> => {
    return avatarClient.listAvatars(payload);
  };

  return useQuery({
    queryKey: ['listAvatars', payload],
    queryFn: listAvatars,
  });
};

export const useActivateAvatar = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const activateAvatar = async (
    payload: ActivateAvatarRequest
  ): Promise<ActivateAvatarResponse> => {
    console.log(payload);
    return avatarClient.activateAvatar(payload);
  };

  return useMutation({
    mutationFn: activateAvatar,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['myProfile'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['currentSquad'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['currentOrg'],
      });
    },
  });
};
