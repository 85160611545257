import { Chip, Tooltip, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import Box from '@mui/material/Box';
import { MemberProps } from '@components/MembesrList/config';
import Card from '@components/Card/Card';
import { TTag } from '@services/api/profiler/tags';
import { Link } from 'react-router-dom';

export const Member = (props: MemberProps) => {
  const { member, currentEntity } = props;

  return (
    currentEntity && (
      <Card>
        <Box display="flex" justifyContent="space-between" position="relative">
          <Link className="NavLink" to={`/user/${member?.profileId}`}>
            <Box display="flex" gap={1} position="relative" sx={{ cursor: 'pointer' }}>
              <Avatar
                sx={{ cursor: 'pointer', color: 'white', width: '35px', height: '35px' }}
                alt={member?.name?.nickName.toUpperCase() || 'User'}
                src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${member?.profileId}/${member?.avatar}`}
              />
              <Box display="flex" flexDirection="column">
                <Typography fontWeight="bold" fontSize="13px">
                  {member?.name?.firstName} {member?.name?.lastName}
                </Typography>
                <Typography color="#bdbdbd" lineHeight="13px" fontSize="13px" mb={1}>
                  @{member?.name?.nickName}
                </Typography>
                <Box display="flex" gap={2} mb={1}>
                  {member?.traits?.experience && (
                    <Tooltip placement="right" title="years">
                      <Typography fontSize="13px">
                        Experience: {member.traits.experience}+
                      </Typography>
                    </Tooltip>
                  )}
                  {member?.traits?.capacity && (
                    <Tooltip placement="right" title="hours per week">
                      <Typography fontSize="13px">Capacity: {member.traits.capacity} </Typography>
                    </Tooltip>
                  )}
                  {member?.traits?.rate && (
                    <Typography fontSize="13px">Rate: ${member.traits.rate}</Typography>
                  )}
                </Box>
                <Box display="flex" gap={1} flexWrap="wrap">
                  {member?.tags?.tags
                    ?.filter((tag: TTag) => tag.tagType.toString() === 'TAG_TYPE_ROLE')
                    .map((tag: TTag) => {
                      return (
                        <Chip
                          sx={{ fontSize: '12px' }}
                          key={tag.value}
                          label={tag.value}
                          variant="outlined"
                        />
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Link>
        </Box>
      </Card>
    )
  );
};
