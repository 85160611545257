import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';

import { useProfiler } from '@store/profiler';

import { DatePickerController } from '@components/Form/controllers/DatePickerController/DatePickerController';
import Box from '@mui/material/Box';
import {
  removeHandler,
  selectHandler,
  statusChangeHandler,
} from '@components/TagAutocomplete/config';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { TTag } from '@services/api/profiler/tags';
import TagAutocomplete from '@components/TagAutocomplete/TagAutocomplete';
import * as React from 'react';
import { useTags } from '@store/tags';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { organizeTagsByType } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { FIELD_LABELS, FieldNames, getDefaultValues, myProfileNameFormSchema } from './config';

type ProfileNameFormProps = {
  isOnboarding?: () => void;
};

export const ProfileNameForm = (props: ProfileNameFormProps) => {
  const { isOnboarding } = props;
  const { getCountries, countries, addTags, removeTags, setTagstatus } = useTags();
  const { userProfile, updateProfileName } = useProfiler();
  const defaultTags =
    userProfile?.tags?.tags &&
    organizeTagsByType(userProfile?.tags?.tags?.filter((tag) => tag.tagType !== TagType.COUNTRY));

  return userProfile ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile) }}
      submitHandler={(data) => {
        if (isOnboarding) {
          isOnboarding();
        }
        updateProfileName(data);
      }}
      resolverSchema={myProfileNameFormSchema}
    >
      <Stack spacing={3} direction="column" mb={2} className="second-step">
        <InputController
          name={`names.${FieldNames.FIRSTNAME}`}
          label={FIELD_LABELS[FieldNames.FIRSTNAME]}
        />
        <InputController
          name={`names.${FieldNames.LASTNAME}`}
          label={FIELD_LABELS[FieldNames.LASTNAME]}
        />
        <InputController
          name={`names.${FieldNames.NICKNAME}`}
          label={FIELD_LABELS[FieldNames.NICKNAME]}
        />
        <DatePickerController name={FieldNames.BIRTHDAY} />
        <TagAutocomplete
          items={countries}
          getItems={getCountries}
          defaultItems={defaultTags?.TAG_TYPE_COUNTRY || []}
          label="Locations you usually work from"
          statusChanger={(id, status) => {
            statusChangeHandler(
              id,
              {
                tagType: TagType.COUNTRY,
                entity: TypeOfEntity.USER,
                entityId: userProfile?.profileId,
                status,
              },
              setTagstatus
            );
          }}
          onSelect={(tag: TTag) =>
            selectHandler(
              tag,
              {
                tagType: TagType.COUNTRY,
                entity: TypeOfEntity.USER,
                entityId: userProfile?.profileId,
              },
              addTags
            )
          }
          onRemove={(tag: TTag) =>
            removeHandler(
              tag,
              {
                tagType: TagType.COUNTRY,
                entity: TypeOfEntity.USER,
                entityId: userProfile?.profileId,
              },
              removeTags
            )
          }
        />
      </Stack>
      <Box display="flex" justifyContent="center">
        <LoadingButton type="submit" variant="contained">
          {isOnboarding ? 'Next' : 'Update profile info'}
        </LoadingButton>
      </Box>
    </Form>
  ) : null;
};
