import { Page } from '@components/Page/Page';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import Container from '@mui/material/Container';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Products from '@components/Products/Products';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import SquadTags from '@pages/Squads/ExploreSquads/SquadTags';
import { useProfiler } from '@store/profiler';
import { replaceDashesWithSpaces, replaceSpacesWithDashes } from '@utils/parseStrings';
import Avatar from '@mui/material/Avatar';
import SquadMembersList from '@pages/Squads/SquadMembersList';
import SettingsIcon from '@mui/icons-material/Settings';
import { ROUTES_PREFIXES } from '@common/routes';

export const Squad = () => {
  const { name } = useParams();
  const { listRoles, userProfile, getListMembers } = useProfiler();
  const { data: squad, error } = useGetSquad({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });

  const requiredRoles = [
    IAMRoleStrings.IAM_ROLE_ADMIN,
    IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_MEMBER,
  ];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[squad?.squad?.squadId as keyof typeof listRoles]?.find(
      (el) => el.id === userProfile?.profileId
    )?.roles.roles
  );

  useEffect(() => {
    if (squad?.squad?.squadId) {
      getListMembers({ id: squad.squad?.squadId, type: TypeOfEntity.SQUAD });
    }
  }, [getListMembers, squad]);

  return (
    <Page description="Squad" title="Squad">
      <Container>
        <EntityInviteCard entityId={squad?.squad?.squadId} entity={squad?.squad} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              <Avatar
                sx={{ width: 200, height: 200, cursor: 'pointer' }}
                variant="rounded"
                src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${squad?.squad?.squadId}/${squad?.squad?.avatar}`}
              />
            </Box>
            <Box width="100%">
              <Box display="flex" alignItems="center" gap={2}>
                <Typography variant="subtitle1">{squad?.squad?.name}</Typography>
                {hasAccess && squad?.squad?.name && (
                  <Link
                    className="NavLink"
                    to={`${ROUTES_PREFIXES.SQUADS}/${replaceSpacesWithDashes(
                      squad?.squad?.name
                    )}/manage`}
                  >
                    <SettingsIcon cursor="pointer" />
                  </Link>
                )}
              </Box>
              <Typography flexGrow="grow" sx={{ color: '#bbb' }} variant="subtitle2">
                {squad?.squad?.about || 'No title'}
              </Typography>
            </Box>
          </Box>
          <Divider />
          {squad?.squad && <Products id={squad.squad.squadId} type={TypeOfEntity.SQUAD} />}
          <Divider />
          {hasAccess && squad?.squad ? (
            <SquadMembersList entityId={squad.squad.squadId} />
          ) : (
            <SquadTags />
          )}
        </Box>
      </Container>
    </Page>
  );
};
