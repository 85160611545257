import React, { useEffect } from 'react';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import { useAuth } from '@store/auth';
import { SignInRequest, SignInRequest_Invitation } from '@proto/iam/auth/v1/auth_pb';

const CheckUrlParams: React.FC = () => {
  const { isLoading, showOtpInput, preSignIn, signIn, device } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('email') && searchParams.has('code')) {
      const email = searchParams.get('email') ? decodeURIComponent(searchParams.get('email')!) : '';
      const code = searchParams.get('code') ? decodeURIComponent(searchParams.get('code')!) : '';
      console.log(email, code);
      const paramsObject = { email, code };
      signIn(
        new SignInRequest({
          type: {
            case: 'invitation',
            value: new SignInRequest_Invitation({
              email,
              code,
            }),
          },
          device: device || undefined,
        })
      );
    } else {
      console.log('нема');
    }
  }, [signIn, device]);

  return <LoaderWithDelay />;
};

export default CheckUrlParams;
