import React, { Suspense, useEffect } from 'react';
import { ManageMember } from '@components/MembesrList/ManageMember';
import { useProfiler } from '@store/profiler';
import { Link, useParams } from 'react-router-dom';
import { useOrgs } from '@store/orgs';
import Stack from '@mui/material/Stack';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddMemberDialog from '@components/AddMemberDialog/AddMemberDialog';
import { useDialog } from '@components/hooks/useDialog';
import { userSlice } from '@store/profiler/slices';
import { useAppDispatch } from '@store/helpers';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import ManageOrgInvitesList from '@pages/Orgs/ManageOrgs/ManageOrgInvitesList';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ROUTES_PREFIXES } from '@common/routes';

const requiredRoles = [
  IAMRoleStrings.IAM_ROLE_ADMIN,
  IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
  IAMRoleStrings.IAM_ROLE_MANAGER,
  IAMRoleStrings.IAM_ROLE_BILLING_MANAGER,
  IAMRoleStrings.IAM_ROLE_MEMBER,
];

const requiredRolesToAddMember = [
  IAMRoleStrings.IAM_ROLE_ADMIN,
  IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
];

export const ManageOrgMembersList = () => {
  const dispatch = useAppDispatch();
  const { entityId } = useParams();
  const { currentOrg, getCurrentOrg } = useOrgs();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { userProfile, listRoles, listMembers, getListMembers } = useProfiler();
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );
  const hasAccessToAddMember = hasCommonRoles(
    requiredRolesToAddMember,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );
  const {
    actions: { deleteSelectedProfile },
  } = userSlice;

  const closeDialog = () => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    handleClose();
  };

  useEffect(() => {
    if (entityId) {
      getCurrentOrg([entityId]);
      getListMembers({ id: entityId, type: TypeOfEntity.ORG });
    }
  }, [getCurrentOrg, getListMembers, entityId]);

  return hasAccess && currentOrg && currentOrg?.orgId === entityId ? (
    <Page description="Orgs" title="Orgs">
      <Container>
        <Stack gap={2}>
          <Link className="NavLink" to={`${ROUTES_PREFIXES.ORGS}/${entityId}`}>
            <ArrowBackIcon />
          </Link>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography textTransform="uppercase" variant="subtitle1">
              Members
            </Typography>

            {hasAccessToAddMember && (
              <Button
                onClick={handleOpen}
                color="success"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AddIcon color="success" />
                <Typography variant="subtitle2">Add member</Typography>
              </Button>
            )}
          </Stack>
          <ManageOrgInvitesList />
          <Stack gap={2}>
            {listMembers[entityId as keyof typeof listMembers]?.profiles?.profiles?.map(
              (member) => (
                <ManageMember
                  key={member.profileId}
                  currentEntity={currentOrg}
                  entityId={entityId}
                  member={member}
                />
              )
            )}
          </Stack>

          <Suspense fallback={null}>
            <AddMemberDialog
              objectName={currentOrg?.name}
              isOpen={isOpen}
              handleClose={closeDialog}
              entity={TypeOfEntity.ORG}
              entityId={entityId || ''}
            />
          </Suspense>
        </Stack>
      </Container>
    </Page>
  ) : null;
};
