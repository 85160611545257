import * as React from 'react';
import { useState } from 'react';
import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import { sortItemsByDate } from '@pages/Marketplace/Dashboards/config';
import { TFeedList } from '@services/api/marketplace/config';
import PreAppliedEntitySelect from '@pages/Marketplace/Feed/PreAppliedEntitySelect';
import PreAppliedDemandCard from '@pages/Marketplace/Feed/PreAppliedDemandCard';
import Button from '@mui/material/Button';
import { Contractors } from '@proto/marketplace/demand/v1/contractor_pb';
import { useApplyAsContractor } from '@services/api/marketplace/calls';
import FeedOwner from '@pages/Marketplace/Feed/FeedOwner';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

type TFeedListProps = {
  feedItems?: TFeedList;
  feedOwner?: any;
};

const FeedByDemandsDialog = (props: TFeedListProps) => {
  const { feedItems, feedOwner } = props;
  const { displayListType, preAppliesContracts, setDemandId, contractors, currentContractorId } =
    useMarketplace();
  const contractList = Object.values(feedItems?.contracts || {});
  const interviewsList = Object.values(feedItems?.interviews || {});
  const combinedList = [...contractList, ...interviewsList];
  const { mutate, error } = useApplyAsContractor();
  const [errors, setErrors] = useState({});

  const toggleDemand = () => {
    setDemandId(undefined);
  };

  let displayList;
  if (displayListType === 'all') displayList = sortItemsByDate(combinedList);
  if (displayListType === 'contracts') displayList = sortItemsByDate(contractList);
  if (displayListType === 'interviews') displayList = sortItemsByDate(interviewsList);

  return (
    <Dialog open={!!feedItems} onClose={toggleDemand}>
      <Box
        flexDirection="column"
        display="flex"
        gap={1}
        position="relative"
        p={4}
        width={{ sx: '100%' }}
        sx={{ backgroundColor: '#263238' }}
      >
        <Box display="flex" justifyContent="space-between">
          <FeedOwner feedOwner={feedOwner} />
          <Box sx={{ cursor: 'pointer' }} onClick={toggleDemand}>
            <CloseIcon />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={2} width="100%">
          <Box overflow="hidden" display="flex" flexDirection="column" gap={1}>
            <PreAppliedEntitySelect />
            <Box display="flex" flexDirection="column" gap={2}>
              {displayList &&
                displayList.map((demand) => {
                  return <PreAppliedDemandCard isPreApplied showActiveState demand={demand} />;
                })}
            </Box>
          </Box>
          <Button
            fullWidth
            disabled={!contractors?.length}
            variant="outlined"
            onClick={() => {
              preAppliesContracts.map((id) => {
                return contractors?.some((contractor) => contractor.contractId !== id)
                  ? null
                  : setErrors({ [id]: true });
              });

              mutate({
                contractors: new Contractors({
                  contractor: {
                    case: currentContractorId?.case || 'orgId',
                    value: currentContractorId?.id || '',
                  },
                  contracts: contractors || [],
                }),
              });
            }}
          >
            {contractors?.length ? `Apply to ${contractors?.length} contracts` : 'Choose suppliers'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FeedByDemandsDialog;
