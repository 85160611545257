import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useTags } from '@store/tags';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useGetOrg } from '@services/api/profiler/OrgsQueries';
import { replaceDashesWithSpaces } from '@utils/parseStrings';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { groupTagsByType } from '@utils/transformTagsToTagsByType';
import TagAutocomplete from '@components/TagAutocomplete/TagAutocomplete';
import {
  removeHandler,
  selectHandler,
  statusChangeHandler,
} from '@components/TagAutocomplete/config';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { TTag } from '@services/api/profiler/tags';

const OrgTags = () => {
  const { name } = useParams();
  const {
    getCountries,
    getLanguages,
    getIndustryDomains,
    tagsForOrgs,
    addTags,
    removeTags,
    setTagstatus,
  } = useTags();
  const { data: org, error } = useGetOrg({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });

  const getTags = {
    '5': getCountries,
    '1': getLanguages,
    '3': getIndustryDomains,
  };

  const tagsLabels = {
    '5': 'Countries',
    '1': 'Languages',
    '3': 'Industry domains',
  };

  const tagTypetoEnumValue = {
    '5': TagType.COUNTRY,
    '1': TagType.LANGUAGE,
    '3': TagType.INDUSTRY_DOMAIN,
  };

  const defaultTags = org?.org?.tags?.tags && groupTagsByType(org?.org?.tags?.tags);

  console.log('defaultTags', defaultTags);
  return (
    <Stack gap={1} width={{ xs: '100%', md: '40%' }}>
      <Box display="flex" gap={1} flexDirection="column">
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Tags
        </Typography>
        <Box display="flex" flexDirection="column">
          {defaultTags &&
            Object.entries(defaultTags)?.map(([key, value]) => {
              return (
                <TagAutocomplete
                  items={tagsForOrgs[key]}
                  getItems={getTags[key as keyof typeof getTags]}
                  defaultItems={value}
                  label={tagsLabels[key as keyof typeof tagsLabels]}
                  statusChanger={(id, status) => {
                    statusChangeHandler(
                      id,
                      {
                        tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                        entity: TypeOfEntity.ORG,
                        entityId: org?.org?.orgId,
                        status,
                      },
                      setTagstatus
                    );
                  }}
                  onSelect={(tag: TTag) =>
                    selectHandler(
                      tag,
                      {
                        tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                        entity: TypeOfEntity.ORG,
                        entityId: org?.org?.orgId,
                      },
                      addTags
                    )
                  }
                  onRemove={(tag: TTag) =>
                    removeHandler(
                      tag,
                      {
                        tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                        entity: TypeOfEntity.ORG,
                        entityId: org?.org?.orgId,
                      },
                      removeTags
                    )
                  }
                />
              );
            })}
        </Box>
      </Box>
    </Stack>
  );
};
export default OrgTags;
