import { getClient } from '@services/api/helpers';
import { OrgService } from '@proto/profiler/biz/v1/org_connect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateOrgRequest,
  CreateOrgResponse,
  GetOrgRequest,
  GetOrgResponse,
} from '@proto/profiler/biz/v1/org_pb';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { getTagType } from '@store/profiler/helpers';
import { showSnackbar } from '@store/snackbars';
import { useAppDispatch } from '@store/helpers';

export const orgClient = getClient<typeof OrgService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  OrgService
);

export const useCreateOrg = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const createOrg = async (payload: CreateOrgRequest): Promise<CreateOrgResponse> => {
    const orgTags: Tag[] = [];
    Object.entries(payload).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.map((element) => {
          const tag = new Tag({
            tagId: element.tagId,
            value: element.value,
            tagType: getTagType[key as keyof typeof getTagType],
            active: true,
          });
          return orgTags.push(tag);
        });
      }
    });
    return orgClient.createOrg(
      new CreateOrgRequest({
        name: payload.name,
        about: payload.about,
        tags: { tags: orgTags },
      })
    );
  };

  return useMutation({
    mutationFn: createOrg,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchMembershipsAndSquads'] });
      dispatch(
        showSnackbar({ id: 'create-org', message: 'Organization created', severity: 'success' })
      );
    },
    onError: (error) => {
      dispatch(showSnackbar({ id: 'create-org', message: error.message, severity: 'error' }));
    },
  });
};

export const useGetOrg = (payload: { value: string; case: 'name' | 'orgId' }) => {
  const getOrg = async (): Promise<GetOrgResponse> => {
    return orgClient.getOrg(
      new GetOrgRequest({
        by: {
          case: payload.case,
          value: payload.value,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['currentOrg', payload],
    queryFn: getOrg,
  });
};
