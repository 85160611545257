import React from 'react';
import Stack from '@mui/material/Stack';
import { useProfiler } from '@store/profiler';
import { TProfile } from '@services/api/profiler/profile';
import { Typography } from '@mui/material';
import ManageSquadInvitesList from '@pages/Squads/ManageSquads/ManageSquadInvitesList';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import { Member } from '@components/MembesrList/Member';

const SquadMembersList = ({ entityId }: { entityId: string }) => {
  const { listMembers } = useProfiler();
  const { data: squad } = useGetSquad({ case: 'squadId', value: entityId || '' });

  return (
    <Stack gap={0.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Members
        </Typography>
      </Stack>
      <ManageSquadInvitesList />
      <Stack gap={1.5}>
        {squad?.squad &&
          listMembers[entityId as keyof typeof listMembers]?.profiles?.profiles?.map(
            (member: TProfile) => {
              return (
                squad?.squad && (
                  <Member
                    key={member.profileId}
                    currentEntity={squad?.squad}
                    entityId={entityId}
                    member={member}
                  />
                )
              );
            }
          )}
      </Stack>
    </Stack>
  );
};
export default SquadMembersList;
