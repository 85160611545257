import { Page } from '@components/Page/Page';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useOrgs } from '@store/orgs';
import OrgTags from '@pages/Orgs/OrgTags';
import { useProfiler } from '@store/profiler';
import Container from '@mui/material/Container';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';
import ManageOrgKanbanboards from '@pages/Orgs/ManageOrgs/ManageOrgKanbanboards';
import Divider from '@mui/material/Divider';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import OrgMembers from '@pages/Orgs/OrgMembers';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import UpdateOrgAboutForm from '@pages/Orgs/ManageOrgs/UpdateOrgInfoForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Products from '@components/Products/Products';
import { useGetOrg } from '@services/api/profiler/OrgsQueries';
import Avatars from '@components/Avatars/Avatars';
import { replaceDashesWithSpaces } from '@utils/parseStrings';

const ManageOrg = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const { userProfile, listRoles, getListMembers } = useProfiler();
  const { getCurrentOrg } = useOrgs();
  const { data: org, error } = useGetOrg({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });
  const requiredRoles = [IAMRoleStrings.IAM_ROLE_ADMIN, IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER];

  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[org?.org?.orgId as keyof typeof listRoles]?.find(
      (el) => el.id === userProfile?.profileId
    )?.roles.roles
  );

  useEffect(() => {
    if (org?.org?.orgId) {
      getListMembers({ id: org?.org?.orgId, type: TypeOfEntity.ORG });
    }
  }, [getCurrentOrg, getListMembers, org?.org?.orgId]);

  return (
    <Page description="Orgs" title="Orgs">
      <EntityInviteCard entityId={org?.org?.orgId} entity={org?.org} />
      <Container>
        <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              {org?.org && (
                <Avatars
                  link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${org?.org?.orgId}/${org?.org?.avatar}`}
                  entityId={org?.org?.orgId}
                />
              )}
            </Box>
            <Box width="100%">{org?.org && <UpdateOrgAboutForm org={org?.org} />}</Box>
          </Box>
          <Divider />
          <ManageOrgKanbanboards />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <OrgTags />
            {org?.org?.orgId && (
              <Products hasAccess={hasAccess} id={org?.org?.orgId} type={TypeOfEntity.ORG} />
            )}
            {org?.org?.orgId && <OrgMembers entityId={org?.org?.orgId} />}
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default ManageOrg;
