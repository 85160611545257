import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import React from 'react';
import { useMarketplace } from '@store/marketplace';

const displayListTypes = [
  { name: 'all', label: 'All' },
  { name: 'contracts', label: 'Contracts' },
  { name: 'interviews', label: 'Interviews' },
];

const FilterFeedItems = () => {
  const { setDisplayListType, displayListType } = useMarketplace();

  return (
    <Box display="flex" gap={2} py={1}>
      {displayListTypes.map((type) => {
        return (
          <Chip
            onClick={() => setDisplayListType(type.name)}
            sx={{ cursor: 'pointer' }}
            variant={displayListType === type.name ? 'filled' : 'outlined'}
            label={type.label}
          />
        );
      })}
    </Box>
  );
};

export default FilterFeedItems;
