import { Page } from '@components/Page/Page';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Products from '@components/Products/Products';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import ManageSquadMembersList from '@pages/Squads/ManageSquads/ManageSquadMembersList';
import { useProfiler } from '@store/profiler';
import UpdateSquadAboutForm from '@pages/Squads/ManageSquads/UpdateSquadInfoForm';
import Avatars from '@components/Avatars/Avatars';
import { replaceDashesWithSpaces } from '@utils/parseStrings';

const ManageSquad = () => {
  const { name } = useParams();
  const { listRoles, userProfile, getListMembers } = useProfiler();
  const { data: squad } = useGetSquad({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });

  const requiredRoles = [
    IAMRoleStrings.IAM_ROLE_ADMIN,
    IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_MEMBER,
  ];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[squad?.squad?.squadId as keyof typeof listRoles]?.find(
      (el) => el.id === userProfile?.profileId
    )?.roles.roles
  );

  useEffect(() => {
    if (squad?.squad?.squadId) {
      getListMembers({ id: squad.squad?.squadId, type: TypeOfEntity.SQUAD });
    }
  }, [getListMembers, squad]);

  return (
    <Page description="Squad" title="Squad">
      <Box width="60%" m="0 auto">
        <EntityInviteCard entityId={squad?.squad?.squadId} entity={squad?.squad} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              {squad?.squad?.squadId && (
                <Avatars
                  link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${squad?.squad?.squadId}/${squad?.squad?.avatar}`}
                  entityId={squad?.squad?.squadId}
                />
              )}
            </Box>
            <Box width="100%">{squad?.squad && <UpdateSquadAboutForm squad={squad.squad} />}</Box>
          </Box>
          <Divider />
          {squad?.squad && (
            <Products hasAccess={hasAccess} id={squad.squad.squadId} type={TypeOfEntity.SQUAD} />
          )}
          <Divider />
          {squad?.squad && <ManageSquadMembersList entityId={squad.squad.squadId} />}
        </Box>
      </Box>
    </Page>
  );
};

export default ManageSquad;
