import { getClient } from '@services/api/helpers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SquadService } from '@proto/profiler/biz/v1/squad_connect';
import {
  CreateSquadRequest,
  CreateSquadResponse,
  GetSquadRequest,
  GetSquadResponse,
} from '@proto/profiler/biz/v1/squad_pb';
import { useAppDispatch } from '@store/helpers';
import { showSnackbar } from '@store/snackbars';

export const squadClient = getClient<typeof SquadService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  SquadService
);

export const useCreateSquad = () => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const createSquad = async (payload: CreateSquadRequest): Promise<CreateSquadResponse> => {
    return squadClient.createSquad(payload);
  };

  return useMutation({
    mutationFn: createSquad,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchMembershipsAndSquads'] });
      dispatch(
        showSnackbar({
          id: 'createSquad',
          message: 'Squad created successfully',
          severity: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(
        showSnackbar({
          id: 'createSquad',
          message: error.message,
          severity: 'error',
        })
      );
    },
  });
};

export const useGetSquad = (payload: { value: string; case: 'name' | 'squadId' }) => {
  const getSquad = async (): Promise<GetSquadResponse> => {
    return squadClient.getSquad(
      new GetSquadRequest({
        by: {
          case: payload.case,
          value: payload.value,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['currentSquad', payload],
    queryFn: getSquad,
  });
};
