import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { dashboardClient } from '@services/api/marketplace/dashboard';
import {
  CreateDashboardRequest,
  CreateDashboardResponse,
  GetDashboardRequest,
  GetDashboardResponse,
  ListDashboardsRequest,
  ListDashboardsResponse,
  ListFeedItemsRequest,
  ListFeedItemsResponse,
} from '@proto/marketplace/dashboard/v1/dashboad_pb';
import {
  DemandStatus_Status,
  GetDemandRequest,
  GetDemandResponse,
  ListAppliedDemandsRequest,
  ListAppliedDemandsResponse,
  SetDemandStatusRequest,
} from '@proto/marketplace/demand/v1/demand_pb';
import {
  contractClient,
  contractorClient,
  demandClient,
  evaluationClient,
  interviewClient,
} from '@services/api/marketplace/demand';
import { convertDemands, getTagsFromPayload } from '@store/marketplace/helpers';
import {
  AcceptEvaluationScheduleSlotRequest,
  AcceptEvaluationScheduleSlotResponse,
  AddEvaluationScheduleSlotsRequest,
  AddEvaluationScheduleSlotsResponse,
  DeleteEvaluationScheduleSlotRequest,
  DeleteEvaluationScheduleSlotResponse,
  GetEvaluationRequest,
  GetEvaluationResponse,
  ListEvaluationScheduleAcceptedSlotsRequest,
  ListEvaluationScheduleAcceptedSlotsResponse,
  ListEvaluationScheduleSlotsRequest,
  ListEvaluationScheduleSlotsResponse,
} from '@proto/marketplace/demand/v1/evaluation_pb';
import {
  CreateInterviewRequest,
  CreateInterviewRequest_NewInterview,
  CreateInterviewResponse,
  InterviewTraits,
} from '@proto/marketplace/demand/v1/interview_pb';
import { TCreateInterviewForm } from '@pages/Marketplace/ManageDemands/settings';
import { Tags } from '@proto/profiler/tags/v1/tags_pb';
import { TCreateContractForm } from '@pages/Marketplace/Dashboards/CreateContracForm';
import {
  ContractTraits,
  CreateContractRequest,
  CreateContractResponse,
} from '@proto/marketplace/demand/v1/contract_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { sortDemandsBySupply, transformFeedItems } from '@services/api/marketplace/config';
import { ApplyOnContractsRequest, Contractors } from '@proto/marketplace/demand/v1/contractor_pb';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { showSnackbar } from '@store/snackbars';
import { useAppDispatch } from '@store/helpers';
import { marketplaceSlice } from '@store/marketplace/slices';
import { Profile } from '@proto/profiler/user/v1/profile_pb';
import { Org } from '@proto/profiler/biz/v1/org_pb';

const { setContractors, setFeedOwner } = marketplaceSlice.actions;

// Hook for getting a demand
export const useGetDemand = (payload: { demandId: string; type: 'contract' | 'interview' }) => {
  const getDemand = async (): Promise<GetDemandResponse> => {
    return demandClient.getDemand(
      new GetDemandRequest({
        id: {
          case: payload.type,
          value: payload.demandId,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['getDemand', payload.demandId],
    queryFn: getDemand,
    staleTime: 5 * 60 * 1000,
  });
};

// Hook for getting an evaluation
export const useGetEvaluation = (payload: { evaluationId: string | undefined }) => {
  const getEvaluation = async (): Promise<GetEvaluationResponse> => {
    return evaluationClient.getEvaluation(
      new GetEvaluationRequest({ evaluationId: payload.evaluationId })
    );
  };

  return useQuery({
    queryKey: ['getEvaluation', payload.evaluationId],
    queryFn: getEvaluation,
  });
};

// // Hook for suggesting evaluation schedule slots
// export const useSuggestEvaluationScheduleSlots = () => {
//     const suggestEvaluationScheduleSlots = async (
//         payload: SuggestEvaluationScheduleSlotsRequest
//     ): Promise<SuggestEvaluationScheduleSlotsServiceResponse> => {
//         return evaluationClient.suggestEvaluationScheduleSlots(payload);
//     };
//     const queryClient = useQueryClient();
//
//     return useMutation({
//         mutationFn: suggestEvaluationScheduleSlots,
//         onSuccess: () => {
//             queryClient.invalidateQueries({queryKey: ['listEvaluationScheduleSlots']});
//         },
//     });
// };

export const useAddEvaluationScheduleSlot = () => {
  const addEvaluationScheduleSlot = async (
    payload: AddEvaluationScheduleSlotsRequest
  ): Promise<AddEvaluationScheduleSlotsResponse> => {
    return evaluationClient.addEvaluationScheduleSlots(payload);
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addEvaluationScheduleSlot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEvaluationScheduleSlots'] });
    },
  });
};

export const useDeleteEvaluationScheduleSlot = () => {
  const deleteEvaluationScheduleSlot = async (
    payload: DeleteEvaluationScheduleSlotRequest
  ): Promise<DeleteEvaluationScheduleSlotResponse> => {
    return evaluationClient.deleteEvaluationScheduleSlot(payload);
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEvaluationScheduleSlot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEvaluationScheduleSlots'] });
    },
  });
};

// Hook for accepting evaluation schedule slots
export const useAcceptEvaluationScheduleSlot = () => {
  const acceptEvaluationScheduleSlot = async (
    payload: AcceptEvaluationScheduleSlotRequest
  ): Promise<AcceptEvaluationScheduleSlotResponse> => {
    return evaluationClient.acceptEvaluationScheduleSlot(payload);
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: acceptEvaluationScheduleSlot,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listEvaluationScheduleSlots'] });
    },
  });
};

// Hook for listing dashboards
export const useListDashboards = () => {
  const listDashboards = async (): Promise<ListDashboardsResponse> => {
    return dashboardClient.listDashboards(new ListDashboardsRequest());
  };

  return useQuery({
    queryKey: ['listDashboards'],
    queryFn: listDashboards,
    staleTime: 5 * 60 * 1000,
  });
};

export const useListAppliedDemands = () => {
  const listAppliedDemands = async (): Promise<ListAppliedDemandsResponse> => {
    return demandClient.listAppliedDemands(new ListAppliedDemandsRequest());
  };

  return useQuery({
    queryKey: ['listAppliedDemands'],
    queryFn: listAppliedDemands,
    select: (response) => {
      return {
        demands:
          response?.demands && sortDemandsBySupply(transformFeedItems(response?.demands.demands)),
      };
    },
  });
};

export const useListFeedItems = () => {
  const listFeedItems = async (): Promise<ListFeedItemsResponse> => {
    return dashboardClient.listFeedItems(
      new ListFeedItemsRequest({
        recommendations: true,
        filter: {
          case: 'offset',
          value: new Timestamp(),
        },
      })
    );
  };

  return useQuery({
    queryKey: ['listFeedItems'],
    queryFn: listFeedItems,
    select: (response) => {
      return {
        feedItems: response?.demands && transformFeedItems(response?.demands.demands),
        recomendations: response?.demands?.recommendations,
        sas: response?.dashboardActiveDemands,
      };
    },
  });
};

export const useListFeedItemsByDemand = (payload: Entity | undefined) => {
  const listFeedItems = async (): Promise<ListFeedItemsResponse> => {
    return dashboardClient.listFeedItems(
      new ListFeedItemsRequest({
        recommendations: true,
        filter: {
          case: 'demand',
          value: payload!,
        },
      })
    );
  };

  return useQuery({
    queryKey: ['listFeedItemsByDemand', payload?.id],
    queryFn: listFeedItems,
    enabled: Boolean(payload),
    select: (response) => {
      return {
        feedItems: response?.demands && transformFeedItems(response?.demands.demands),
        recommendations: response.demands?.recommendations,
        feedOwner: response.demands?.owner &&
          Object.keys(response.demands?.owner).length && {
            ownerName:
              (response.demands?.owner?.owner?.value as Org)?.name ||
              `${(response.demands?.owner?.owner?.value as Profile)?.name?.firstName || ''} ${
                (response.demands?.owner?.owner?.value as Profile)?.name?.lastName || ''
              }`,
            ownerNickname: (response.demands?.owner?.owner?.value as Profile)?.name?.nickName,
            ownerId:
              (response.demands?.owner?.owner?.value as Org)?.orgId ||
              (response.demands?.owner?.owner?.value as Profile)?.profileId,
            ownerType: response.demands.owner.owner.case,
          },
      };
    },
  });
};

export const useClearListFeedItemsByDemand = () => {
  const queryClient = useQueryClient();

  const clearListFeedItemsByDemand = (id?: string) => {
    if (id) {
      queryClient.invalidateQueries({ queryKey: ['listFeedItemsByDemand', id] });
    } else {
      queryClient.invalidateQueries({ queryKey: ['listFeedItemsByDemand'] });
    }
  };

  return clearListFeedItemsByDemand;
};

export const useListEvaluationScheduleAcceptedSlots = () => {
  const listEvaluationScheduleAcceptedSlots =
    async (): Promise<ListEvaluationScheduleAcceptedSlotsResponse> => {
      return evaluationClient.listEvaluationScheduleAcceptedSlots(
        new ListEvaluationScheduleAcceptedSlotsRequest()
      );
    };

  return useQuery({
    queryKey: ['listEvaluationScheduleAcceptedSlots'],
    queryFn: listEvaluationScheduleAcceptedSlots,
  });
};

// Hook for getting a dashboard
export const useGetDashboard = (dashboardId: string) => {
  const getDashboard = async (): Promise<GetDashboardResponse> => {
    return dashboardClient.getDashboard(new GetDashboardRequest({ dashboardId }));
  };

  return useQuery({
    queryKey: ['getDashboard', dashboardId],
    queryFn: getDashboard,
    select: (data) => ({
      // @ts-ignore
      demands: convertDemands(data?.demands?.demands),
      kanbanBoard: data.dashboard.value,
      recommendations: data.demands?.recommendations,
    }),
  });
};

// Hook for creating a dashboard
export const useCreateDashboard = () => {
  const queryClient = useQueryClient();

  const createDashboard = async (
    payload: CreateDashboardRequest
  ): Promise<CreateDashboardResponse> => {
    return dashboardClient.createDashboard(payload);
  };

  return useMutation({
    mutationFn: createDashboard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listDashboards'] });
    },
  });
};

// Hook for listing evaluation schedule slots
export const useListEvaluationScheduleSlots = (payload: { evaluationId: string }) => {
  const listEvaluationScheduleSlots = async (): Promise<ListEvaluationScheduleSlotsResponse> => {
    return evaluationClient.listEvaluationScheduleSlots(
      new ListEvaluationScheduleSlotsRequest({ evaluationId: payload.evaluationId })
    );
  };

  return useQuery({
    queryKey: ['listEvaluationScheduleSlots', payload.evaluationId],
    queryFn: listEvaluationScheduleSlots,
    staleTime: 5 * 60 * 1000,
    retry: false,
  });
};

export const useCreateInterview = () => {
  const queryClient = useQueryClient();

  const createInterview = async (
    payload: TCreateInterviewForm
  ): Promise<CreateInterviewResponse> => {
    return interviewClient.createInterview(
      new CreateInterviewRequest({
        dashboard: {
          case: 'id',
          value: payload.dashboardId,
        },
        requirements: payload.requirements,
        interview: {
          value: new CreateInterviewRequest_NewInterview({
            traits: new InterviewTraits({
              price: payload.price,
              experience: payload.experience,
            }),
            tags: new Tags({
              tags: getTagsFromPayload(payload),
            }),
          }),
          case: 'newInterview',
        },
      })
    );
  };

  return useMutation({
    mutationFn: createInterview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useCreateContract = () => {
  const queryClient = useQueryClient();

  const createContract = async (payload: TCreateContractForm): Promise<CreateContractResponse> => {
    return contractClient.createContract(
      new CreateContractRequest({
        dashboard: {
          case: 'id',
          value: payload.dashboardId,
        },
        traits: new ContractTraits({
          rate: payload.rate,
          capacity: payload.capacity,
          experience: payload.experience,
        }),
        description: payload.description,
        tags: new Tags({
          tags: getTagsFromPayload(payload),
        }),
        createInterview: payload.createInterview,
      })
    );
  };

  return useMutation({
    mutationFn: createContract,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useSetDemandstatus = () => {
  const setDemandStatus = async (payload: { demandId: string; status: DemandStatus_Status }) => {
    return demandClient.setDemandStatus(
      new SetDemandStatusRequest({
        demandId: payload.demandId,
        status: payload.status,
      })
    );
  };
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setDemandStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDemand'] });
      queryClient.invalidateQueries({ queryKey: ['getDashboard'] });
    },
  });
};

export const useApplyAsContractor = () => {
  const applyAsContractor = async (payload: { contractors: Contractors }) => {
    return contractorClient.applyOnContracts(
      new ApplyOnContractsRequest({
        applyAs: {
          case: 'contractors',
          value: payload.contractors,
        },
      })
    );
  };
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: applyAsContractor,
    onSuccess: () => {
      dispatch(setContractors(undefined));
      dispatch(
        showSnackbar({
          id: 'apply-as-contractor',
          message: 'You have successfully applied to the demands',
          severity: 'success',
        })
      );
    },
  });
};
