import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import * as React from 'react';
import { ProfileNameForm } from '@pages/ProfileSettings/ProfileNameForm/ProfileNameForm';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { ProfileTraitsForm } from '@pages/ProfileSettings/ProfileTraitsForm/ProfileTraitsForm';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateSquadButton from '@pages/Squads/ManageSquads/CreateSquadButton';

const steps = [
  'Fill in your personal info',
  'Tell about your expertise\n',
  'Set up your work details\n',
];

const Onboarding = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const setStep = (step: number) => {
    setActiveStep(step);
  };

  const renderStepContent = {
    0: <ProfileNameForm isOnboarding={handleNext} />,
    1: <ProfileTags isOnboarding={handleNext} />,
    2: <ProfileTraitsForm isOnboarding={handleNext} />,
  };

  return (
    <Page description="Onboarding" title="Onboarding">
      <Container>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setStep(index)}
                  key={label}
                  {...stepProps}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box display="flex" flexDirection="column" gap={2}>
            {activeStep === steps.length ? (
              <Box
                display="flex"
                p={5}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="subtitle2">
                  Congratulations! You have successfully completed your profile What do you want to
                  do next?
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} gap={1}>
                  <Link to="/">
                    <Button variant="contained" onClick={handleReset}>
                      Explore Squady on my own
                    </Button>
                  </Link>
                  <CreateSquadButton type="onboarding" />
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  <ArrowBackIcon />
                </Button>
              </Box>
            )}
            {renderStepContent[activeStep as keyof typeof renderStepContent]}
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default Onboarding;
