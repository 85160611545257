import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import React, { Dispatch, SetStateAction } from 'react';
import { useOrgs } from '@store/orgs';
import { Org } from '@proto/profiler/biz/v1/org_pb';
import { setGroupStatus } from '../../helpers/setGroupStatus';

type OrgStatusSwitcherProps = {
  orgStatus: boolean | undefined;
  setStatusOrg: Dispatch<SetStateAction<boolean>>;
  org: Org;
};

const OrgStatusSwitcher = (props: OrgStatusSwitcherProps) => {
  const { orgStatus, setStatusOrg, org } = props;
  const { setOrgStatus } = useOrgs();

  return (
    <Box>
      <Switch
        defaultChecked={org?.active}
        color="success"
        onChange={(e, checked) => {
          setStatusOrg(checked);
          setGroupStatus(setOrgStatus, org?.orgId, checked);
        }}
      />
    </Box>
  );
};

export default OrgStatusSwitcher;
