import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Page } from '@components/Page/Page';
import React, { useEffect, useState } from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Box from '@mui/material/Box';
import { useProfiler } from '@store/profiler';
import Joyride, { CallBackProps } from 'react-joyride';
import { findToolTips, State } from '@utils/joyrideSettings';
import { ToolTips } from '@proto/profiler/user/v1/tool_tips_pb';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import Avatars from '@components/Avatars/Avatars';
import { useGetMyProfile } from '@services/api/profiler/ProfilesQueries';
import { ProfileNameForm } from './ProfileNameForm/ProfileNameForm';
import { ProfileTraitsForm } from './ProfileTraitsForm/ProfileTraitsForm';
import { ProfileActiveStatusSwitch } from './ProfileActiveStatusSwitch/ProfileActiveStatusSwitch';

const profileSettingsSteps = [
  {
    content: <Typography>so that everyone can see how beautiful you are</Typography>,
    title: 'Upload your avatar',
    target: '.first-step',
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    content: <Typography>provide basic information about yourself</Typography>,
    title: 'fill in your information',
    disableScrolling: true,
    spotlightPadding: 20,
    target: '.second-step',
  },
  {
    content: <Typography>tell us a little more about yourself</Typography>,
    title: 'Select tags',
    placement: 'bottom',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.third-step',
  },
  {
    content: <Typography>specify the path to your resources</Typography>,
    title: 'Add a links',
    placement: 'top',
    target: '.fourth-step',
  },
  {
    content: <Typography>indicate what remuneration you are willing to work for</Typography>,
    title: 'Add traits',
    placement: 'left',
    target: '.fifth-step',
  },
];

export const ProfileSettings = () => {
  const { finishToolTips, userProfile, tooltipsList } = useProfiler();
  const { data: myProfile, error } = useGetMyProfile();
  const [{ run, steps }, setState] = useState<State>({
    run: false,
    // @ts-ignore
    steps: profileSettingsSteps,
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      run: !findToolTips(tooltipsList, 'TOOL_TIPS_PROFILE_SETTINGS'),
    }));
  }, [tooltipsList]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;
    if (action === 'skip' || (action === 'close' && status === 'finished')) {
      finishToolTips(ToolTips.PROFILE_SETTINGS);
    }
  };
  return userProfile ? (
    <Page description="Profile" title="Profile">
      <Box width="50%" m="0 auto">
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          spotlightClicks
          steps={steps}
          styles={{
            options: {
              primaryColor: '#757ce8',
              textColor: '#fff',
              zIndex: 10,
              backgroundColor: '#1A1831',
            },
          }}
        />
        <Grid container spacing={2}>
          <Grid container item>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }} mb={2}>
                <AccountBoxIcon />
                <Typography pl={1} variant="subtitle1">
                  Profile
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} display="flex" justifyContent="space-between">
              <Stack spacing={2} direction="column">
                <Avatars
                  link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${myProfile?.profile?.profileId}/${myProfile?.profile?.avatar}`}
                  entityId={userProfile?.profileId || ''}
                />
                <ProfileActiveStatusSwitch />
              </Stack>
            </Grid>
            <Grid item xs={12} md={9}>
              <ProfileNameForm />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mb={1.5}>
                Expertise
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} className="third-step">
              <ProfileTags />
            </Grid>
          </Grid>
          <Grid container item spacing={4} xs={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mb={1.5}>
                Work details
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ProfileTraitsForm />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  ) : null;
};
