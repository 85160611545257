import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import CreateSquadButton from '@pages/Squads/ManageSquads/CreateSquadButton';
import CreateOrgButton from '@pages/Orgs/CreateOrgButton';
import CreateDashboardButton from '@pages/Marketplace/CreateDashboardButton';
import { useProfiler } from '@store/profiler';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const CreateEntityMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { userProfile } = useProfiler();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    if (event?.target.closest('.MuiDialog-root')) {
      event.stopPropagation();
      return;
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick} variant="outlined" sx={{ padding: '5px 15px !important' }}>
        <AddIcon />
        <ArrowDropDownIcon />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={{ py: '5px' }}>
          <MenuItem>
            <CreateDashboardButton />
          </MenuItem>
          <Divider />
          <MenuItem>
            <CreateSquadButton type="entityMenu" />
          </MenuItem>
          <MenuItem>
            <CreateOrgButton />
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default CreateEntityMenu;
